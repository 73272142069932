import React,{useEffect, useState} from "react";
import DatePicker from "react-datepicker"
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
export default function Gestiones(){

    const fechaActual = new Date()
    const [startDate, setStartDate] = useState(fechaActual);
    const [endDate, setEndDate] = useState(fechaActual);
    const [archivos, setArchivos] = useState(null);

    const subirArchivos = e => {
        setArchivos (e);
    }
 
    
    const insertarArchivos = async() =>{
        const f = new FormData();

        for (let index = 0; index < archivos.length; index++) {
           f.append("files", archivos[index]);
            
    
        }
     await axios.post("//localhost:3000/images/", f)
     .then (response =>{
        console.log(response.data);
     })
      .catch (error =>{
        console.log(error);
     })
    }
    
            
    return(
        <>
        <div className="p-20">
            <div>
                <h1 className="text-3xl font-bebas text-center">SUBIR FANCY</h1>
            </div>
       <form className="max-w-xl mx-auto bg-zinc-300 rounded-xl p-10" >
  <div className="mb-5">
  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="user_avatar">Upload file</label>
  <input onChange={(e)=>subirArchivos(e.target.files)} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" type="file"/>
  
  <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">A profile picture is useful to confirm your are logged into your account</div>
  <div>
    <label>DESDE:</label>
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      showTimeSelect
      excludeTimes={[
        setHours(setMinutes(new Date(), 0), 17),
        setHours(setMinutes(new Date(), 30), 18),
        setHours(setMinutes(new Date(), 30), 19),
        setHours(setMinutes(new Date(), 30), 17),
      ]}
      dateFormat="MMMM d, yyyy h:mm aa"
    />
    <label>HASTA:</label>
    <DatePicker
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      showTimeSelect
      excludeTimes={[
        setHours(setMinutes(new Date(), 0), 17),
        setHours(setMinutes(new Date(), 30), 18),
        setHours(setMinutes(new Date(), 30), 19),
        setHours(setMinutes(new Date(), 30), 17),
      ]}
      dateFormat="MMMM d, yyyy h:mm aa"
    />

  </div>
  </div>
  <Link onClick={()=>insertarArchivos()} className="text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">SUBIR</Link>

</form>
</div> </>
    )
}
import React from 'react'

const LoaderFormal = (props) => {

  if (props.loaded) {
        return (
          <div className="loadingWrap">
            <div id="loading-bar-spinner" className="spinner">
              <div className="spinner-icon"></div>
            </div>
            </div>
              );
            } else {
              return null;
            }
}

export default LoaderFormal

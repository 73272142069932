import React, { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom'
import { IoCalendarOutline } from "react-icons/io5";
import {
  agregarMesaEnStockEnDemanada,
  armarPoliticas,
  armarPromociones,
  capitalizeFirstLetter,
  convertirFecha,
  getURLBasePath,
} from "../../components/tools/utils";
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';


const FormularioReserva = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [tiposDeDocumento, setTiposDeDocumento] = useState([]);
  //const [ddjj, setDdjj] = useState(false);
  const { reserva, evento, onHandleTableChange, history } = props;
  const { mesas: mesasElegidas } = reserva;

  


  const generateSessionId = () => {
    const cryptoNumber = ([1e7] + -1e3 + -4e3 + -8e3).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
    const uuid = `${cryptoNumber}-${Date.now()}`;
    return uuid;
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (mesasElegidas.length > 0) {
      agregarMesaEnStockEnDemanada(evento.id, mesasElegidas, 0, 3);
      onHandleTableChange([]);
    }

    if (!Number.isInteger(Number())) {
      history.push("/");
      return;
    }

    const token = localStorage.getItem("sessionId");
    if (!token) {
      const sessionId = generateSessionId();
      localStorage.setItem("sessionId", sessionId);
    }

    const data = { peticion: 8 };
    const url = `${getURLBasePath()}/serviciosEmpleados.php`;
    fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: new Headers(),
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      //.then(response => console.log('Success:', response));
      .then(function (resultado) {
        if (resultado) {
          setTiposDeDocumento(resultado);
        }
      });
    /*
    const data = { peticion: 4, idEvento: props.match.params.idEvento };

     fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: new Headers(),
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      //.then(response => console.log('Success:', response));
      .then(function (resultado) {
        if (resultado) {
          setLoaded(true);
          props.setearEventoApp(resultado);
        }
      }); */
  }, []);

  const handleInputChange = (e) => {
    
    props.onInputsChangeApp(e.target.name, e.target.value);
    
  };

  const [titularReserva, setTitularReserva] = useState('')
 const [tipoNumeroDocumento, setTipoNumeroDocumento] = useState ()
 const [direccion, setDireccion] = useState()
 const [email, setEmail] = useState()
 const [telefono, setTelefono] = useState()
 const [cantidadPersonas, setCantidadPersonas] = useState('')
 const [observaciones, setObservaciones] = useState(null)
const [cantidadMenores, setCantidadMenores] = useState('')


 useEffect(() => {
  reserva.paxAdultos = cantidadPersonas
}, [cantidadPersonas])

useEffect(() =>{
  reserva.titular = titularReserva
}, [titularReserva])

useEffect(() =>{
  reserva.direccion = direccion
}, [direccion])

useEffect(() =>{
  reserva.email = email
}, [email])

useEffect(() =>{
  reserva.telefono = telefono
}, [telefono])

useEffect(() =>{
  reserva.observaciones = observaciones
}, [observaciones])


useEffect(() =>{
  reserva.numeroDocumento = tipoNumeroDocumento
}, [tipoNumeroDocumento])

useEffect(()=> {
  reserva.paxMenores = cantidadMenores
}, [cantidadMenores])


const handleCantidadMenores = (e) =>{
setCantidadMenores(e.target.value)

}

const handleTitularReserva = (e) =>{
  setTitularReserva(e.target.value)
  reserva.titular = titularReserva
 
}

const handleSelecionDocumento = (e) =>{
  const tipoDocumento = e.target.value
  reserva.idTipoDocumento = tipoDocumento;
  
}

const handleTipoNumeroDocumento =(e) => {
setTipoNumeroDocumento(e.target.value)
reserva.numeroDocumento = tipoNumeroDocumento

}

const handleDireccion = (e) =>{
  setDireccion(e.target.value)
  reserva.direccion = direccion
 
}

const handleEmail = (e) =>{
  setEmail(e.target.value)
  reserva.email = email
 
}

const handleTelefono = (e) => {
  setTelefono(e.target.value)
  reserva.telefono = telefono
  
}



const handleCantidadPersonas = (e) => {
  e.preventDefault()
  setCantidadPersonas(e.target.value)
  
  
}

const handleObservaciones = (e) => {

  setObservaciones(e.target.value)

}

let navigate = useNavigate();

  const handleTipoDocumentoChange = (e) => {
    const tipoDocumento = tiposDeDocumento.find(
      (tip) => tip.id === Number(e.target.value)
    );
    props.onInputsChangeApp("idTipoDocumento", tipoDocumento.id);
    

  };
  // const handleDDJJChange = (e) => {
  //   setDdjj(e.target.checked);
  // };

 const handleSiguienteClick = (e) => {
  e.preventDefault();
 e.preventDefault();
    if (
      !reserva.titular ||
      !reserva.email ||
      !reserva.telefono ||
      !reserva.direccion ||
      !reserva.numeroDocumento ||
      reserva.paxAdultos == 0
    ) {
      toast.error('Complete el formulario.', { duration: 4000, position: 'top-center'})
      return;
    }

    if (
      parseInt(reserva.paxAdultos) > 10 ||
      parseInt(reserva.paxMenores) > 10 ||
      parseInt(reserva.paxAdultos) + parseInt(reserva.paxMenores) > 10
    ) {
      toast.error('Podra reservar un máximo de 10 Personas.', { duration: 4000, position: 'top-center'})
      props.resetearCantidad();
      return;
    }

    //controlar que no exista dni para mismo evento
    const data = {
      peticion: 53,
      idEvento: evento.id,
      numeroDocumento: reserva.numeroDocumento,
    };
    const url = `${getURLBasePath()}/serviciosReserva.php`;
    fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: new Headers(),
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      //.then(response => console.log('Success:', response));
      .then(function (resultado) {
        if (resultado.estado === "error") {
          if (!window.confirm(resultado.mensaje)) {
            return true;
          }
        }

        if (evento.productoM.ocupaSilla === 0) {
         navigate('/reserva/ModoPago');
        
          return;
        }

        navigate('/other-page');
      });
     
     

  };

  return (
    <div>
      {/*<HeroContainer img="../images/atardecer-up.jpg"></HeroContainer>*/}
      {/* <LoaderFormal loaded={!loaded} /> */}
      <div className="pt-20 md:m-20 text-white">
        <div className="bg-gray-700 p-10 md:p-5 md:rounded-xl ">
          
          <div className="justify-center flex">
          <IoCalendarOutline className="h-9 w-7"/> 
          <h1 className="text-center font-bebas text-4xl">
        {capitalizeFirstLetter(convertirFecha(props.evento.fecha))}.
        
        </h1>
        
          </div>
       
        <h5 className="text-lg font-montserrat text-center">Podra reservar un máximo de 10 Personas.</h5>
        <p className=" text-center text-lg">
        <b >Tarifa por:</b>
        <br />
        <div>
          Adultos: ${(evento.productoM.tarifasM.tarifaAdulto)} - Menores: ${(evento.productoM.tarifasM.tarifaMenor)}
        </div>
        
      </p>
      </div>
</div>
    
      
     

      {evento.promocionMovArray && evento.promocionMovArray.length > 0 && (
        <p className="resaltar">
          <b>Promociones Vigentes</b>
          <br />
          {armarPromociones(evento)}
          {/* Si la reserva presenta 3 personas. El 3ro tendra un 20% de descuento.<br />
              Si la reserva presenta 4 personas. Los el 3ro y 4to tendran un 25% de descuento.<br /> */}
        </p>
      )}

      
      <div id="eventoformulario">
        {props.evento.idEvento ? (
          
          <form className="bg-white grid  md:m-20 p-10 rounded md:rounded-xl ">
            <div className="flex flex-wrap -mx-3 mb-6  gap-0 md:gap-5">
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Nombre y Apellido *</label>
              <input
                name="titular"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="Escribir aquí"
                required=""
                onChange={handleTitularReserva}/>
                


            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="idTipoDocumento">
                Tipo de Documento *
              </label>
              <select
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                name="idTipoDocumento"
                onChange={handleSelecionDocumento}
                
                style={{ height: "60px" }}
              >
                <option disabled value="">
                  Tipo Documento
                </option>
                {tiposDeDocumento.map((tipoDeDocumento) => {
                  return (
                    <option key={tipoDeDocumento.id} value={tipoDeDocumento.id}>
                      {tipoDeDocumento.descripcion}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">DNI</label>
              <input
                name="numeroDocumento"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="Escribir aquí"
                required=""
                
                onChange={handleTipoNumeroDocumento}
              />
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Direccion</label>
              <input
                name="direccion"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="Escribir aquí"
                required=""
                
                onChange={handleDireccion}
              />
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Email</label>
              <input
                name="email"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="email"
                placeholder="Escribir aquí"
                required=""
                
                onChange={handleEmail}
              />
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Teléfono</label>
              <input
                name="telefono"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="tel"
                placeholder="Escribir aquí"
                required=""
               
                onChange={handleTelefono}
              />
            </div>

            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Cantidad de Adultos:
                {/*${" "} {props.evento.productoM.tarifasM.tarifaAdulto} x pax */}
              </label>
              <input
                name="paxAdultos"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                placeholder="Ingrese cantidad de personas"
                required=""
               
                onChange={handleCantidadPersonas}
              />
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
               Cantidad de menores (4 a 11 años): 
               
              </label>
              <input
                name="paxMenores"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                placeholder="Ingrese cantidad de personas"
                
               
                onChange={handleCantidadMenores}
              />
            </div>
            {/* <div className="col-sm-6">
              <label>
                Cantidad de Menores. ${" "}
                {props.evento.productoM.tarifasM.tarifaMenor} x pax (entre
                3 y 11)
              </label>
              <input
                name="paxMenores"
                className="form-control input-lg"
                type="number"
                placeholder="Escribir aquí"
                value={reserva.paxMenores}
                onChange={handleInputChange}
              />
            </div> */}

            {/* <div className="col-sm-12">
              <p>
                Declaración Jurada COVID-19.{" "}
                <a
                  href="https://estanciamargarita.com.ar/reservar/assets/declaracion-jurada-covid.pdf"
                  target="_blank"
                  download="Declaracion-Jurada-Covid-19"
                  rel="noopener noreferrer"
                >
                  Haga click aquí para descargar la DDJJ, luego podrá avanzar al
                  siguiente paso
                </a>
                . Cada pasajero, deberá llevar una copia impresa completa para
                presentar en la Estancia.
              </p>
              <input
                name="ddjj"
                className=""
                type="checkbox"
                value="He descargado la DDJJ de Covid-19"
                onChange={handleDDJJChange}
              />{ ' ' }
              <label> He descargado la DDJJ de Covid-19</label>
            </div> */}

            <div className="w-full md:w-1/1 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Observaciones</label>
              <textarea
                name="observacion"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                rows="5"
                type="text"
                placeholder="Cualquier comentario que sienta oportuno que sepamos"
                
               
                onChange={handleObservaciones}
                
              />
              
</div>
<div className="w-full md:w-1/1 px-3 mb-6 md:mb-0">
              <Link className="float-left inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-md font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none" to="/">
                Volver
              </Link>
              <Link
                className="float-right inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-md font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none "
                onClick={handleSiguienteClick}
              >  Siguiente
              </Link>
              </div>

            
            </div>
          </form>
        ) : null
      }
      </div>
    </div>
  );
};

export default FormularioReserva;

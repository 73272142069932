import React from 'react'
import HeroContainer from '../HeroContainer'

const productos = [
    {
      "Producto": "Agua",
      "Precio": 1500
    },
    {
      "Producto": "Soda",
      "Precio": 1500
    },
    {
      "Producto": "Coca Cola",
      "Precio": 2000
    },
    {
      "Producto": "Coca Zero",
      "Precio": 2000
    },
    {
      "Producto": "Sprite",
      "Precio": 2000
    },
    {
      "Producto": "7Up",
      "Precio": 2000
    },
    {
      "Producto": "7 Up Light",
      "Precio": 2000
    },
    {
      "Producto": "Vino de la Casa 500 cc",
      "Precio": 5000
    },
    {
      "Producto": "Trumpeter",
      "Precio": 9000
    },
    {
      "Producto": "Uxman",
      "Precio": 7000
    },
    {
      "Producto": "Cosecha Tardia",
      "Precio": 6000
    },
    {
      "Producto": "Don Valentin",
      "Precio": 7000
    },
    {
      "Producto": "Estancia Mendoza",
      "Precio": 6000
    },
    {
      "Producto": "Lopez",
      "Precio": 6000
    },
    {
      "Producto": "Cerveza",
      "Precio": 4000
    },
    {
      "Producto": "Papas Fritas",
      "Precio": 2500
    },
    {
      "Producto": "Empanada",
      "Precio": 700
    },
    {
      "Producto": "Pastelitos",
      "Precio": 500
    },
    {
      "Producto": "Torta Frita",
      "Precio": 300
    },
    {
      "Producto": "Colacion de Membrillo",
      "Precio": 500
    },
    {
      "Producto": "Colacion de Batata",
      "Precio": 500
    },
    {
      "Producto": "Colacion de Dulce de Leche",
      "Precio": 500
    },
    {
      "Producto": "Empanadilla de Batata",
      "Precio": 700
    },
    {
      "Producto": "Piscina",
      "Precio": 2000
    }
  ]
export const ListadoOpcionales = () => {
  return (
    <div className=''>
         <HeroContainer img={'images/home-up.jpg'} text1="Menu" text2="Opcionales"/>
          <section id="opcionales" className="max-w-screen-xl mx-auto md:mx-20 md:rounded-xl  py-20 px-8 lg:px-10 bg-amber-100  ">
        <h1 className='bold text-center text-3xl font-bebas'>Listado de Opcionales NOVIEMBRE 2024:</h1>
        <div className='relative overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 '>
        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
          <tr>
            <th scope="col" class="px-6 py-3">Producto</th>
            <th scope="col" class="px-6 py-3">Precio</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto, index) => (
            <tr key={index} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
              <td className='px-6 py-3'>{producto.Producto}</td>
              <td className='px-6 py-3'>${producto.Precio}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
</section>
    </div>
  )
}

export default ListadoOpcionales;
import React, {Component} from 'react';
import toast from 'react-hot-toast';


const Wait = () => toast.loading('Espere...', { duration: 4000, position: 'top-center'});

class Form extends Component {
    state = {
        name: '',
        apellido: '',
        email: '',
        number: '',
        message: '',
        emailStatus: '',
    };

    // handle the value
    handleCHange = input => e => {
        this.setState({[input]: e.target.value});
    }

    // when submit btn is clicked
    submitForm = (e) => {
        const {name, apellido, email, number, message} = this.state;
        
        

        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();
    
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the response state and the step
            toast.success('Mensaje enviado con Exito')
        });
        // open the request with the verb and the url
        xhr.open('POST', 'https://estanciamargarita.com.ar/backend/index.php?sendto=' 
                                         + email + 
                                '&name=' + name + 
                                '&apellido=' + apellido +
                                '&number=' + number +
                                '&message=' + message);
                                
        // send the request
        xhr.send(
            toast.loading('Espere...', { duration: 4000, position: 'top-center'})
        );

        // reset the fields
        this.setState({
            name: '',
            apellido: '',
            email: '',
            message: '',
            number: '',
        });
        e.preventDefault();
    }

    render(){
        const {name, apellido, email, message, number, emailStatus} = this.state;
        return(
            
            <div >
                {emailStatus ? emailStatus : null}
            <form encType="multipart/form-data" onSubmit={this.submitForm}>
            <div className="bg-white col-span-2 py-14 lg:px-10 px-8 text-gray-800">
              <h2 className="text-3xl font-medium font-bebas">Ingrese sus datos</h2>
             <div className="grid md:grid-cols-2 grid-col-1 gap-4">
                <div className="flex flex-col py-4">
                <label htmlFor="First Name" className="text-base font-medium">Nombre</label>
                <input type="text" name="nombre" value={name} onChange={this.handleCHange('name')} required className="outline-none border-b-2 border-solid focus:border-red-400 transition-all"/>
              </div>
               <div className="flex flex-col py-4">
                <label htmlFor="Apellido" className="text-base font-medium">Apellido</label>
                <input type="text" name="apellido" value={apellido} onChange={this.handleCHange('apellido')} required className="outline-none border-b-2 border-solid focus:border-red-400 transition-all"/>
              </div>
              <div className="flex flex-col py-4">
                <label htmlFor="Number" className="text-base font-medium">Numero de telefono</label>
                <input type="text" name="number" value={number} onChange={this.handleCHange('number')} required className="outline-none border-b-2 border-solid focus:border-red-400 transition-all"/>
              </div>
              <div className="flex flex-col py-4">
                <label htmlFor="Number" className="text-base font-medium">Mail</label>
                <input type="text" name="email" value={email} onChange={this.handleCHange('email')} required type="email" className="outline-none border-b-2 border-solid focus:border-red-400 transition-all"/>
              </div>
             </div>
              
                <div className="flex flex-col py-4 pb-8">
                <label htmlFor="Message" className="text-base font-medium">Mensaje</label>
                <textarea name="text" name="mensaje" value={message} required onChange={this.handleCHange('message')} id="" cols="20" rows="4" className="outline-none border-b-2 border-solid focus:border-red-400 transition-all"></textarea>
              </div>
              <input type="submit"  className="bg-yellow-600 px-8 py-3 text-2xl text-white hover:bg-white hover:text-yellow-400 border-2 border-solid border-yellow-500 transition-all"/>
            </div>
            </form>
           
            </div>


        );
    }
}

export default Form;
import React,{useState, useEffect} from 'react'
import EventoFila from './EventoFila/EventoFila.js'
import { addDays, getURLBasePath} from '../../components/tools/utils';

import Loader from "react-js-loader";


class ListadoEventos extends React.Component {

  
  constructor(props) {
      super(props)
      this.state = {eventos: [], id: 'a', loaded: false};
      this.handleChangeEvento = this.handleChangeEvento.bind(this);
      
     
  }


  componentDidMount() {
    let _this = this;
    var url = `${getURLBasePath()}/serviciosEventos.php`;
    //var data = {peticion: 6};
    var data = {peticion: 23, objeto: {llamadoDesde: 'W', queTipoDeProductos: 0, filtrarContenido: 1}};

   
    fetch(url, {
      method: 'POST', 
      body: JSON.stringify(data), 
      headers: new Headers()
    })
    .then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(function (resultado) {
      if (resultado && resultado.estado == 'exito') {
        if (Array.isArray(resultado.data)) {
          _this.setState({eventos: resultado.data, loaded:true});
        }
      }
    });
  }

  array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; 
  };

  elegirFondo(array) {
    //array[Math.floor(Math.random() * array.length)]
    this.array_move(array, 0, array.length - 1);

    return array[0]
  }

  filtrarEventosPorFecha(fechaEvento) {
    let diaEvento = addDays(fechaEvento,1);
    let hoy = new Date() ;
        hoy.setHours(0,0,0,0);
    let maniana = addDays(hoy,1);
    //let pasado = addDays(hoy,2);
    if (diaEvento.getTime()=== hoy.getTime() || diaEvento.getTime() === maniana.getTime())
      return true;

    return false;
    
  }

  handleChangeEvento(e) {
      const eventoSeleccionado = this.state.eventos.find(ev => ev.idEvento == e.target.value);
      this.props.onEventoClick(eventoSeleccionado);
      this.props.history.push('/formulario/' + e.target.value);
  }


  
    render() {
        const imagenes = ['images/reserva/bienvenidos.jpg', 'images/reserva/carro.jpg', 'images/reserva/nenes.jpg', 'images/reserva/carro.jpg', 'images/reserva/arbol.jpg'];
        
        
const Cargador = () => {
  return (
    <>
    
            <div className="p-20 bg-amber-600 min-h-screen flex justify-center items-center">
                <div className="item">
                    <Loader type="bubble-ping" bgColor={'#d5d5d5'} color={'#f4f4f4'} title={"Cargando reservas hasta la fecha."} size={150} />
                </div>
                </div>
                </>
  )
}
        return (
          <div className='md:pt-20'>

{console.log('Conexion: ',this.state.loaded)}
         
          <>
          
          <div>{(this.state.loaded===false) ? <><Cargador/></> : <></>}</div>
           
                {this.state.eventos &&
                  this.state.eventos.map((evento, index) => {
                    if (!this.filtrarEventosPorFecha(evento.fecha))
                          return (<EventoFila key={index} miEvento={evento} onEventoClick={this.props.onEventoClick} imagen={this.elegirFondo(imagenes)} />)

                    return null;
                })
                }
                </>
          
          </div>
        );
    }
}

export default ListadoEventos

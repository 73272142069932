import React, { useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import HeroContainer from '../components/HeroContainer'





export default function Itinerario(props){
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [bookToggle, setBookToggle] = React.useState();

  React.useEffect(() => {
    windowWidth >= 768 ? setBookToggle(900) : setBookToggle((window.innerWidth - 17));
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, [windowWidth]);

    return(
        
        <>
        <HeroContainer img="images/atardecer-up.jpg" text1="Nuestro" text2="Itinerario" />
        <div className="justify-self-center m-10">
          <div className="">
        <Document file="./pdf/itinerario.pdf" > <Page pageNumber={1}  width={bookToggle}/> </Document>
        </div>
      </div>

   
    


        </>
    )
}
import React, {Component, useLayoutEffect} from 'react';
import { BrowserRouter as Enrutador, Routes, Route, Switch} from 'react-router-dom';
import Home from '../pages/Home';
import Sobre from '../pages/Sobre';
import Gallery from '../pages/Gallery';
import Layout from '../layouts/Layout';
import Itinerario from '../pages/Itinerario';
import Contact from '../pages/Contact'
import FormularioReserva from '../components/FormularioReserva/FormularioReserva'
import Pagar from '../components/Pagar/Pagar'
import ListadoEventos from './ListadoEventos/ListadoEventos';
import SeleccionDeMesas from '../components/SeleccionDeMesas/SeleccionDeMesas'
import Finalizacion from '../components/Finalizacion/Finalizacion'
import Error from '../components/Finalizacion/Error'
import Succes from '../components/Finalizacion/Success'
import Gestiones from '../pages/Gestiones';
import ListadoOpcionales from '../components/ListadoOpcionales/ListadoOpcionales';



class Router extends Component {

  
  constructor(props) {
      super(props);
      this.state = {reserva: {idEntidad: 23, totalReserva: 0, tarifaBaseAdulto:0, tarifaBaseMenor: 0,
                            paxTotales: 0, paxAdultos:'', paxMenores: '', observacion: "", direccion: '', idTipoDocumento: 1,
                            numeroDocumento: '',
                            idEvento: 0, titular:'', email: '', telefono: '', evento: {fecha: ''},  mesas: [], sillas: [] },
                    evento: {cantidad:0, reservasTotalPax:0,
                            productoM: { tarifasM: {
                                                    tarifaAdulto: 0,
                                                    tarifaMenor: 0
                                                  }
                                       }
                            }
                  };


      this.handleInputChange = this.handleInputChange.bind(this);
      //this.handleInputTarifasChange = this.handleInputTarifasChange.bind(this);
      this.handleEvento = this.handleEvento.bind(this);
      this.handleResetearPersonas = this.handleResetearPersonas.bind(this);
      this.handleTableChange = this.handleTableChange.bind(this);
      this.handleChairChange = this.handleChairChange.bind(this);
  }

  handleEvento(evento) {
    //let evento = Object.assign({}, this.state.reserva);
    this.setState({evento});
  }

  handleInputChange(name, value){
    //this.props.onInputsChange(e.target.name, e.target.value);

    let reserva = Object.assign({}, this.state.reserva);
    //reserva.titular = e.target.value;
    reserva[name] = value;
    this.setState({reserva: reserva});
  }

  handleTableChange(value){
    //this.props.onInputsChange(e.target.name, e.target.value);

    let reserva = Object.assign({}, this.state.reserva);
    //reserva.titular = e.target.value;
    reserva.mesas = value;
    this.setState({reserva: reserva});
  }

  handleChairChange(value){
    //this.props.onInputsChange(e.target.name, e.target.value);
    let reserva = Object.assign({}, this.state.reserva);
    //reserva.titular = e.target.value;
    reserva.sillas = value;
    this.setState({reserva: reserva});
  }

  handleResetearPersonas() {

    let reserva = Object.assign({}, this.state.reserva);
    reserva.paxAdultos = 0;
    reserva.paxMenores = 0;

    this.setState({reserva: reserva});
  }

  calcularTotal() {
    return ((Number(this.state.evento.productoM.tarifasM.tarifaAdulto) * this.state.reserva.paxAdultos) + (Number(this.state.evento.productoM.tarifasM.tarifaMenor) * this.state.reserva.paxMenores)).toFixed(2) ;
  }

  calcularTotalPax() {
    return Number(this.state.reserva.paxAdultos) + Number(this.state.reserva.paxMenores);
  }

  render() {
    const FormularioReservaConProps = (props) => {
      return (
        <FormularioReserva 
          {...props} 
          reserva={this.state.reserva}
          evento={this.state.evento}
          onInputsChangeApp={this.handleInputChange}
          totalReserva={this.calcularTotal()}
          totalPax={this.calcularTotalPax()}
          setearEventoApp={this.handleEvento}
          resetearCantidad={this.handleResetearPersonas} 
          onHandleTableChange={this.handleTableChange}
        />
      );
    }

    const PagarConProps = (props) => {
      return (
        <Pagar 
          {...props} 
          reserva={this.state.reserva}
          totalReserva={this.calcularTotal()}
          totalPax={this.calcularTotalPax()}
          evento={this.state.evento}
        />
      );
    }

    const MapaMesas = (props) => {
      return (
        <SeleccionDeMesas 
          {...props} 
          reserva={this.state.reserva}
          totalReserva={this.calcularTotal()}
          totalPax={this.calcularTotalPax()}
          evento={this.state.evento}
          onHandleTableChange={this.handleTableChange}
          onHandleChairChange={this.handleChairChange}
        />
      );
    }

    const ListadoEventosConProps = (props) => {
      return (
        <ListadoEventos 
          {...props} 
          onEventoClick={this.handleEvento}
        />
      );
    }

  return (
    <>
   <Enrutador>
 <Routes>

 <Route exact path="/" element={<Layout />}>
 <Route index element={<Home />}></Route>
 <Route path="/sobre/" element={<Sobre/>}></Route>
 <Route path="/gallery" element={<Gallery/>}></Route>
 <Route path="/itinerario" element={<Itinerario/>}></Route>
 <Route path="/contacto" element={<Contact />}></Route>
 <Route path="/reserva/" element={<ListadoEventosConProps/>}></Route>
 <Route path="/opcionales/" element={<ListadoOpcionales/>}></Route>
<Route path="/formulario/:idEvento" element= {<FormularioReservaConProps/>}></Route>
<Route path="/finalizacion/:idReserva/:estado" element={<Finalizacion/>}></Route>
<Route path="/reserva/modoPago" element={<PagarConProps/>}></Route>
<Route path="/gestiones" element={<Gestiones/>}></Route>
<Route path="*" element={<Home/>}></Route>
</Route>

 </Routes>
 </Enrutador>
  
   </>
  );
}
}

export default Router;
import React, { useState } from 'react';
import PureModal from 'react-pure-modal';
import {RUTA_IMAGEN} from '../components/tools/rutasfijas';
import 'react-pure-modal/dist/react-pure-modal.min.css';



export class Modal extends React.Component {
  constructor(props) { 
    super(props);
    this.state = { change: true };
    
  }
  

    getCuerpo(fancy) {
        return <img src={`${RUTA_IMAGEN}${fancy}`}/>; 
    }
    render(){
      const cuerpo = this.getCuerpo(this.props.fancy);
  return (

    <div>
    <PureModal
  

  isOpen={this.state.change}
  
  width={500}
  onClose={() => {
    this.setState({ change: false});


  }}
>

 <div>{cuerpo}</div>
</PureModal>;
    </div>
  );
}}


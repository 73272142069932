import { Link } from "react-router-dom";

export default function Footer (){
    return(
        <>
        <footer className="bg-white lg:grid lg:grid-cols-5">
  <div className="relative block h-32 lg:col-span-2 lg:h-full">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.433254802001!2d-58.97080992520888!3d-34.66901326094787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bc8c2ba4de3b39%3A0xbd36cbaca2036f06!2sEstancia%20La%20Margarita!5e0!3m2!1ses!2sar!4v1715353126616!5m2!1ses!2sar" className="relative block h-32 lg:col-span-2 lg:h-full w-full"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>

  <div className="px-4 py-3 sm:px-6 lg:col-span-3 lg:px-8 ">
    <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 text-center sm:text-left">
      <div>
        <p>
          <span className="text-xs uppercase tracking-wide text-gray-500"> Numero de Contacto: </span>

          <Link to="https://wa.me/1161490517?text=Hola!+quiero+reservar+mi+estadia+en+Estancia+La+Margarita+" target="_blank"  className="block text-2xl font-medium text-gray-900 hover:opacity-75 sm:text-3xl ">
          11 6149-0517
          </Link>
        </p>
        <p>
          <span className="text-sm uppercase tracking-wide text-gray-500 ">Ubicacion: </span>

          <a href="/" className="block text-sm font-medium text-gray-900 hover:opacity-75 md:text-md ">
            Ubicación:
            Olavarria S/N, Barrio Mi Rincon, B1748 General Rodriguez, Pcia de Buenos Aires
          </a>
        </p>

        <ul className="mt-8 space-y-1 text-sm text-gray-700">
          
          <li>Lunes a Viernes: 9:30 am - 18:30 pm</li>
        </ul>

        
      </div>

      
    </div>

    <div className="mt-12 border-t border-gray-100 ">
      <div className="sm:flex sm:items-center sm:justify-between ">
       

        <p className="mt-8 text-xs text-gray-500 sm:mt-0 text-center">
          &copy; 2024. Estancia La Margarita.
        </p>
      </div>
    </div>
  </div>
</footer>
</>
    )
}
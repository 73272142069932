import React, {children} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Home1 from './gallerys/Home-1'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



function slider() {

   return(
    <>
 <Swiper
     modules={[Navigation, Pagination, Scrollbar, A11y]}
     spaceBetween={20}
     slidesPerView={3}
     navigation
     loop={true}
     pagination={{ clickable: true }}
    
     scrollbar={{ draggable: true }}
     
    >
        { Home1.map(Home1 =>{
            return (<SwiperSlide key={Home1.key}> <img className='rounded-xl shadow-xl' src={Home1.src}/> </SwiperSlide>);
        })
        
        }
    </Swiper>
    </>
   );
    
}
export default slider;